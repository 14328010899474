import "core-js/stable";
import "regenerator-runtime/runtime";

// detect webp support and add modifier class to HTML element if so (for CSS background-image use)
const webP = new Image();
webP.onload = () => {
    document.querySelector('html').classList.add('supports-webp');
};
webP.src = 'data:image/webp;base64,UklGRjoAAABXRUJQVlA4IC4AAACyAgCdASoCAAIALmk0mk0iIiIiIgBoSygABc6WWgAA/veff/0PP8bA//LwYAAA';


import "./js/config.js";

// Load in vendor scripts (jquery loaded by Webpack directly);
import "bootstrap";

// Lazysizes
import 'lazysizes';
import 'lazysizes/plugins/native-loading/ls.native-loading';

// Load in styles
import "./scss/styles.scss";

// Conditional dynamic imports

// Webpack will store those modules in separate JS files and
// load them on demand, keeping payload down.


// Load in custom JS modules required on initial page load

if (document.querySelector('body.site-layout') != null) {
    import('./js/keyboard-accessibility-check');
    import('./js/scrolling');
}

if (document.querySelector('#main-menu') != null) {
    import('./js/main-navigation');
}

if (document.querySelector('.emergency-message') != null) {
    import('./js/alert-message');
}

// Load in custom JS modules on initial page load and when DOM is updated

const addDataJsParsed = (elements, tag = '') => {
    elements.forEach(x => {
        x.setAttribute(`data-js-parsed--${tag}`, '');
    });
}

const jsModuleCheck = async () => {

    let testQuery = null;

    // defer-iframe-content.js
    testQuery = document.querySelector('iframe.defer-iframe-content[data-src]:not([data-js-parsed--defer-iframe-content])');
    if (testQuery != null) {
        let thisDomQuery = document.querySelectorAll('iframe.defer-iframe-content[data-src]:not([data-js-parsed--defer-iframe-content])');
        addDataJsParsed(thisDomQuery, "defer-iframe-content");
        import('./js/defer-iframe-content').then((js) => {
            js.createFrom(thisDomQuery);
        });
    }

    // data-sizes-js-fix (fixes lazysizes issue)
    testQuery = document.querySelector('*[data-sizes-js-fix]:not([data-js-parsed--data-sizes-js-fix])');
    if (testQuery != null) {
        let thisDomQuery = document.querySelectorAll('*[data-sizes-js-fix]:not([data-js-parsed--data-sizes-js-fix])');
        addDataJsParsed(thisDomQuery, "data-sizes-js-fix");
        import('./js/data-sizes-js-fix').then((js) => {
            js.createFrom(thisDomQuery);
        });
    }

    // hero video - vimeo
    testQuery = document.querySelector('.hero--video.vimeo:not([data-js-parsed--vimeo])');
    if (testQuery != null) {
        let thisDomQuery = document.querySelectorAll('.hero--video.vimeo:not([data-js-parsed--vimeo])');
        addDataJsParsed(thisDomQuery, "vimeo");
        import('./js/hero-video-vimeo').then((js) => {
            js.createFrom(thisDomQuery);
        });
    }

    // hero video - youtube
    testQuery = document.querySelector('.hero--video.youtube:not([data-js-parsed--youtube])');
    if (testQuery != null) {
        let thisDomQuery = document.querySelectorAll('.hero--video.youtube:not([data-js-parsed--youtube])');
        addDataJsParsed(thisDomQuery, "youtube");
        import('./js/hero-video-youtube').then((js) => {
            js.createFrom(thisDomQuery);
        });
    }

    // countdown
    testQuery = document.querySelector('.countdown:not([data-js-parsed--countdown])');
    if (testQuery != null) {
        let thisDomQuery = document.querySelectorAll('.countdown:not([data-js-parsed--countdown])');
        addDataJsParsed(thisDomQuery, "countdown");
        import('./modules/countdown/countdown.js').then((js) => {
            js.createFrom(thisDomQuery);
        });
    }

    // hunt-watch
    testQuery = document.querySelector('.hunt-watch:not([data-js-parsed--hunt-watch])');
    if (testQuery != null) {
        let thisDomQuery = document.querySelectorAll('.hunt-watch:not([data-js-parsed--hunt-watch])');
        addDataJsParsed(thisDomQuery, "hunt-watch");
        import('./js/hunt-watch').then((js) => {
            js.createFrom(thisDomQuery);
        });
    }

    // smooth-scroll-link
    testQuery = document.querySelector('a.smooth-scroll-link:not([data-js-parsed--smooth-scroll-link])');
    if (testQuery != null) {
        let thisDomQuery = document.querySelectorAll('a.smooth-scroll-link:not([data-js-parsed--smooth-scroll-link])');
        addDataJsParsed(thisDomQuery, "smooth-scroll-link");
        import('./js/smooth-scroll-link').then((js) => {
            js.createFrom(thisDomQuery);
        });
    }

    // heading fx
    testQuery = document.querySelector('.heading-fx[data-heading-fx]:not([data-js-parsed--heading-fx])');
    if (testQuery != null) {
        let thisDomQuery = document.querySelectorAll('.heading-fx[data-heading-fx]:not([data-js-parsed--heading-fx])');
        addDataJsParsed(thisDomQuery, "heading-fx");
        import('./js/heading-fx').then((js) => {
            js.createFrom(thisDomQuery);
        });
    }

    // better-than-native bootstrap carousel swipe detection
    testQuery = document.querySelector('.carousel:not([data-js-parsed--bs-carousel])');
    if (testQuery != null) {
        let thisDomQuery = document.querySelectorAll('.carousel:not([data-js-parsed--bs-carousel])');
        addDataJsParsed(thisDomQuery, "bs-carousel");
        import('./js/bootstrap-carousel-swipe').then((js) => {
            js.createFrom(thisDomQuery);
        });
    }

    // image-loader-group
    testQuery = document.querySelector('.image-loader-group:not([data-js-parsed--image-loader-group])');
    if (testQuery != null) {
        let thisDomQuery = document.querySelectorAll('.image-loader-group:not([data-js-parsed--image-loader-group])');
        addDataJsParsed(thisDomQuery, "image-loader-group");
        import('./js/image-loader-group').then((js) => {
            js.createFrom(thisDomQuery);
        });
    }

    // non-lazyload img-load-watcher
    testQuery = document.querySelector('img.img-load-watcher:not([data-js-parsed--img-load-watcher])');
    if (testQuery != null) {
        let thisDomQuery = document.querySelectorAll('img.img-load-watcher:not([data-js-parsed--img-load-watcher])');
        addDataJsParsed(thisDomQuery, "img-load-watcher");
        import('./js/img-load-watcher').then((js) => {
            js.createFrom(thisDomQuery);
        });
    }

    // height matching
    testQuery = document.querySelector('*[data-hm-group]:not([data-js-parsed--height-match])');
    if (testQuery != null) {
        let thisDomQuery = document.querySelectorAll('*[data-hm-group]:not([data-js-parsed--height-match])');
        addDataJsParsed(thisDomQuery, "height-match");
        import('./js/height-match').then((js) => {
            js.createFrom(thisDomQuery);
        });
    }

    // lightboxes (PhotoSwipe v5 - galleries and single images)
    testQuery = document.querySelector('.lightbox-image:not([data-js-parsed--lightbox]), .image-gallery:not([data-js-parsed--lightbox])');
    if (testQuery != null) {
        let thisDomQuery = document.querySelectorAll('.lightbox-image:not([data-js-parsed--lightbox]), .image-gallery:not([data-js-parsed--lightbox])');
        addDataJsParsed(thisDomQuery, "lightbox");
        import('./modules/lightboxes-galleries/lightboxes.js').then((js) => {
            js.createFrom(thisDomQuery);
        });
    }
        
    // tripadvisor icon shiv
    if (document.querySelector('.fa-tripadvisor') != null) {
        import('./js/tripadvisor-icon-shiv').then((js) => {
            js.createFrom(document.querySelectorAll('.fa-tripadvisor'));
        });
    }
    
    // tiktok icon shiv
    if (document.querySelector('.fa-tiktok') != null) {
        import('./js/tiktok-icon-shiv').then((js) => {
            js.createFrom(document.querySelectorAll('.fa-tiktok'));
        });
    }

    // smart-bootstrap-tabs
    testQuery = document.querySelector('.nav[role="tablist"]:not([data-js-parsed--smart-bootstrap-tabs])');
    if (testQuery != null) {
        let thisDomQuery = document.querySelectorAll('.nav[role="tablist"]:not([data-js-parsed--smart-bootstrap-tabs])');
        addDataJsParsed(thisDomQuery, "smart-bootstrap-tabs");
        import('./js/smart-bootstrap-tabs').then((js) => {
            js.createFrom(thisDomQuery);
        });
    }

    // collapse - expand for desktop
    testQuery = document.querySelector('.expand-for-desktop:not([data-js-parsed--expand-for-desktop])');
    if (testQuery != null) {
        let thisDomQuery = document.querySelectorAll('.expand-for-desktop:not([data-js-parsed--expand-for-desktop])');
        addDataJsParsed(thisDomQuery, "expand-for-desktop");
        import('./js/collapse-expand-for-desktop').then((js) => {
            js.createFrom(thisDomQuery);
        });
    }

    // stars-planet theme - planet scaling
    testQuery = document.querySelector('.theme--stars-planet:not([data-js-parsed--stars-planet])');
    if (testQuery != null) {
        let thisDomQuery = document.querySelectorAll('.theme--stars-planet:not([data-js-parsed--stars-planet])');
        addDataJsParsed(thisDomQuery, "stars-planet");
        import('./js/stars-planet').then((js) => {
            js.createFrom(thisDomQuery);
        });
    }

    // testimonials-carousel
    testQuery = document.querySelector('.testimonials-carousel:not([data-js-parsed--testimonials-carousel])');
    if (testQuery != null) {
        let thisDomQuery = document.querySelectorAll('.testimonials-carousel:not([data-js-parsed--testimonials-carousel])');
        addDataJsParsed(thisDomQuery, "testimonials-carousel");
        import('./modules/testimonials-carousel/testimonials-carousel').then((js) => {
            js.createFrom(thisDomQuery);
        });
    }

    // responsive nav bar
    testQuery = document.querySelector('.responsive-nav-bar:not([data-js-parsed--responsive-nav-bar])');
    if (testQuery != null) {
        let thisDomQuery = document.querySelectorAll('.responsive-nav-bar:not([data-js-parsed--responsive-nav-bar])');
        addDataJsParsed(thisDomQuery, "responsive-nav-bar");
        import('./modules/responsive-nav-bar/responsive-nav-bar').then((js) => {
            js.createFrom(thisDomQuery);
        });
    }

    // responsive table
    testQuery = document.querySelector('.responsive-table:not([data-js-parsed--responsive-table])');
    if (testQuery != null) {
        let thisDomQuery = document.querySelectorAll('.responsive-table:not([data-js-parsed--responsive-table])');
        addDataJsParsed(thisDomQuery, "responsive-table");
        import('./modules/responsive-table/responsive-table');
    }

    // timeline
    testQuery = document.querySelector('.timeline:not([data-js-parsed--timeline])');
    if (testQuery != null) {
        let thisDomQuery = document.querySelectorAll('.timeline:not([data-js-parsed--timeline])');
        addDataJsParsed(thisDomQuery, "timeline");
        import('./modules/timeline/timeline');
    }

    // starfield-canvas
    testQuery = document.querySelector('.starfield-canvas:not([data-js-parsed--starfield-canvas])');
    if (testQuery != null) {
        let thisDomQuery = document.querySelectorAll('.starfield-canvas:not([data-js-parsed--starfield-canvas])');
        addDataJsParsed(thisDomQuery, "starfield-canvas");
        import('./modules/starfield-canvas/starfield-canvas').then((js) => {
            js.createFrom(thisDomQuery);
        });
    }

};

//// create a mutation observer to watch for dynamically added HTML

let checkRequested = false, checkTimeout = null;

// run a check on page load
jsModuleCheck();

// Select the node that will be observed for mutations
const targetNode = document.querySelector('main');
if (targetNode != null) {
    // Options for the observer (which mutations to observe)
    const config = { attributes: false, childList: true, subtree: true };
    // Callback function to execute when mutations are observed
    const callback = function (mutationsList) {
        // Use traditional 'for loops' for IE 11
        let checkNeeded = false;
        for (const mutation of mutationsList) {
            if (mutation.type === 'childList' && mutation.addedNodes.length > 0) {
                checkNeeded = true;
            }
        }
        if (checkNeeded && !checkRequested) {
            checkRequested = true;
            clearTimeout(checkTimeout);
            checkTimeout = setTimeout(() => {
                jsModuleCheck();
                checkRequested = false;
            }, 500);
        }
    };
    // Create an observer instance linked to the callback function
    const observer = new MutationObserver(callback);
    // Start observing the target node for configured mutations
    observer.observe(targetNode, config);
}